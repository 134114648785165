// import React from "react";

import {
  selectorSrcmApiStoreDoc,
  selectorSrcmApiStoreOrdered,
} from "gatsby-plugin-hfn-profile/state/selectors";
import ScheduleCard from "./ScheduleCard";
import {
  eventTrackerFormFields,
  postprocess,
  eventTrackerFormDefaults,
  eventsTracketFormValidation,
} from "../../components/crud-shared/eventTrackerFormFields";

const eventsCollection = "srcmevents";

const sEventsId = (state, docId) => {
  let result = {};
  result = selectorSrcmApiStoreDoc(state, eventsCollection, docId);
  return result;
};

const Config = {
  sessionKey: "events-sk1",
  noItemMsg: "No Events Loaded",
  mainTitle: "My Events",

  // different page configurations
  pageOptions: {
    All: {
      optionsSelector: () => [{ key: "All", text: "My Events" }],
      pageSelector: (state) =>
        selectorSrcmApiStoreOrdered(state, eventsCollection),
    },
    // "status": {
    //   optionsSelector: selectorOptionsStatus,
    //   pageSelector: selectorEventsForStatus,
    //   pageDispatcher: statusDispatcher,
    // },
  },

  collection: eventsCollection,
  primaryKey: "name",
  disableEditPrimaryKey: true,
  autogenPrimaryKey: true,

  showTableHeader: false,
  displayAsTable: false,
  renderItemCard: ScheduleCard,

  panelForEditItem: true,

  formFields: eventTrackerFormFields,
  formDefaults: eventTrackerFormDefaults,
  // formValidator: eventFormvalidations,
  formValidator: eventsTracketFormValidation,
  postprocess,

  singleItemSelector: sEventsId,
  singleItemDispatcher: null,

  newBtnText: "Add Event",
  newBtnIcon: "Add",

  // enableBulkUpload: true,
  editBtnText: "Edit Event",
  editBtnType: "action",
  // tableEditButtonProps: { text: "Edit" },
  // tableDeleteButtonProps: {text: "Delete"},

  filterProps: {
    placeholder: "Title / Preview",
    searchFunction: (r) => `${r.title} ${r.preview}`,
    filterMenuItems: [
      {
        key: "date",
        text: "Start Date (Recent First)",
        f: (a, b) => {
          if (a.start_datetime.toLowerCase() < b.start_datetime.toLowerCase()) {
            return 1;
          }
          if (b.start_datetime.toLowerCase() < a.start_datetime.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
      {
        key: "title",
        text: "Name (Ascending)",
        f: (a, b) => {
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }
          if (b.title.toLowerCase() > a.title.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
      {
        key: "title",
        text: "Name (Decending)",
        f: (a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return 1;
          }
          if (b.title.toLowerCase() < a.title.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
      {
        key: "group",
        text: "Group (Ascending)",
        f: (a, b) => {
          if (a.category.name.toLowerCase() > b.category.name.toLowerCase()) {
            return 1;
          }
          if (b.category.name.toLowerCase() > a.category.name.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
      {
        key: "group",
        text: "Group (Decending)",
        f: (a, b) => {
          if (a.category.name.toLowerCase() < b.category.name.toLowerCase()) {
            return 1;
          }
          if (b.category.name.toLowerCase() < a.category.name.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
    ],
  },
};

export default Config;
