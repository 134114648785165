/** @jsx jsx */
import {
  jsx,
  Flex,
  Box,
  Badge,
  Container,
  Grid,
  Input,
  Spinner,
  Text,
  Image,
} from "theme-ui";
import { useState, useRef, useEffect } from "react";
import { DefaultButton } from "office-ui-fabric-react";
import { get } from "lodash";
import { useAlert } from "gatsby-plugin-hfn-profile/alert";
import { Dialog, DialogType } from "office-ui-fabric-react/lib/Dialog";
import { buttonStyles, inputStyles } from "sites-common/utils/fabricStyles";
import PropTypes from "prop-types";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { useUpperFirst } from "sites-common/utils/unCamelCase";
import useOnClickOutside from "sites-common/hooks/useOnClickOutside";
import AppIcons from "sites-common/utils/icons";
import getFormattedDate from "sites-common/utils/FormatDate";
import { appsDialogStyles } from "../../utils/dialogStyles";
import AddSessions from "../../components/AddSessions";
import AttendanceUpload from "../../components/AttendanceUpload";
import AddTestimonial from "../../components/AddTestimonial";
import AddPhotosAndReports from "../../components/AddPhotosAndReports";

function ScheduleCardC({ item, EditItemButton, onSave }) {
  const { showAlert } = useAlert();
  const [showAddSessionDialog, setShowAddSessionDialog] = useState(false);
  const [showAddTestimonialDialog, setShowAddTestimonialDialog] =
    useState(false);
  const [showAddPhotosDialog, setShowAddPhotosDialog] = useState(false);
  const [showAttendanceDialog, setShowAttendanceDialog] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [show, setShow] = useState(false);
  const [attendanceCount, setAttendanceCount] = useState({
    total: 0,
    counted: get(item, "counted_attendance", 0),
    captured: get(item, "captured_attendance", 0),
  });
  const [countValue, setCount] = useState(0);
  useEffect(
    () =>
      setAttendanceCount({
        total: 0,
        counted: get(item, "counted_attendance", 0),
        captured: get(item, "captured_attendance", 0),
      }),
    [item]
  );

  const ref = useRef();
  useOnClickOutside(ref, () => setShow(false), show);

  const updateAttendanceCount = () => {
    const data = {
      // counted_attendance: attendanceCount.counted,
      counted_attendance: countValue,
    };
    setUpdating(true);
    const afterUpdate = () => {
      setCount(0);
      setUpdating(false);
      setShowAttendanceDialog(false);
      const alertInfo = {
        title: "Success",
        message: `Attendance count has been successfully saved.`,
        confirm_text: "Okay",
        is_blocking: true,
      };
      showAlert(alertInfo);
    };

    const afterFailure = () => {
      setUpdating(false);
    };

    onSave(data, afterUpdate, afterFailure, {
      use_method: "PATCH",
    });
  };

  const dismissDialog = () => {
    setShowAttendanceDialog(false);
    setAttendanceCount({
      total: 0,
      counted: get(item, "counted_attendance", 0),
      captured: get(item, "captured_attendance", 0),
    });
    setCount(0);
  };

  const menuItems = [
    {
      label: "Edit Event",
      onClick: <EditItemButton />,
      icon: "edit",
      disabled: false,
      hide: false,
    },
    {
      label: "Add Session",
      onClick: () => {
        setShowAddSessionDialog(true);
      },
      icon: "add",
      disabled: false,
    },
    {
      label: "Add Testimonial",
      onClick: () => {
        setShowAddTestimonialDialog(true);
      },
      icon: "add",
      disabled: false,
    },
    {
      label: "Add Photos / Event Reports",
      onClick: () => {
        setShowAddPhotosDialog(true);
      },
      icon: "add",
      disabled: false,
      hide: false,
    },
    // {
    //   label: "Bulk Upload Attendance",
    //   onClick: () => {
    //     setShowAttendanceDialog(true);
    //   },
    //   icon: "upload",
    //   disabled: false,
    //   hide: false,
    // },
  ];

  const EventsMenuItem = () => {
    return (
      <Container ref={ref}>
        <Box
          onBlur={() => setShow(false)}
          onClick={() => {
            setShow(!show);
          }}
          role="button"
          tabIndex="0"
        >
          <Image
            sx={{
              "&: hover": {
                cursor: "pointer",
              },
            }}
            src={!show ? AppIcons.more_dot : AppIcons.cross_delete}
            alt="event more"
            width="20"
          />
        </Box>
        {show && (
          <Box sx={{ position: "relative", zIndex: 9999 }}>
            <Box
              sx={{
                position: "absolute",
                width: "300px",
                right: 0,
                boxShadow: "0px 3px 6px #00000029",
                backgroundColor: "#ffffff",
                transform: "translate(0, 10px)",
                "@media (max-width: 480px)": {
                  width: "auto",
                },
              }}
            >
              <Box
                sx={{
                  flexBasis: ["auto", 202],
                  order: -1,
                  boxShadow: "0px 3px 6px #00000029",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {menuItems.map(({ label, onClick, icon, disabled }) => (
                    <Box
                      key={label}
                      onClick={label !== "Edit Event" ? onClick : () => {}}
                      sx={{
                        ...{
                          borderBottom: "1px solid #dddddd",
                          cursor: "pointer",
                          margin: "1px",
                          "&:hover": {
                            background: "#E8F5FE",
                            "& > div, i": {
                              color: "#1DA0F2",
                            },
                          },
                          "& > div": {
                            color: "#333333",
                            fontSize: "16px",
                          },
                          "@media (max-width: 480px)": {
                            "& > div": {
                              fontSize: "14px",
                            },
                          },
                        },
                        ...(disabled && {
                          pointerEvents: "none",
                          opacity: 0.7,
                        }),
                      }}
                    >
                      <Box
                        sx={{
                          px: 3,
                          py: label === "Edit Event" ? 2 : 3,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        {label === "Edit Event" && (
                          <EditItemButton
                            sx={{
                              width: "100%",
                              fontSize: "16px",
                              color: "#333333",
                              "@media (max-width: 480px)": {
                                fontSize: "14px",
                              },
                            }}
                          />
                        )}
                        {label !== "Edit Event" && (
                          <Icon iconName={icon} sx={{ width: 25 }} />
                        )}
                        {label !== "Edit Event" && label}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Container>
    );
  };

  return (
    <Container
      sx={{
        color: "#333333",
        letterSpacing: "0.24px",
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        zIndex: 9999,
      }}
      p={3}
    >
      <Grid
        columns={[3, "6fr 3fr 1fr"]}
        pb={3}
        sx={{
          "@media (max-width: 600px)": {
            display: "block",
          },
        }}
      >
        <Box
          sx={{
            fontWeight: "medium",
            fontSize: 3,
          }}
        >
          <Text mr={2}>{useUpperFirst(item.title)}</Text>
          <Badge
            sx={{
              bg: "#228B22",
              "@media (max-width: 600px)": {
                ml: 0,
              },
            }}
          >
            {get(item, "category.name")}
          </Badge>
        </Box>
        <Box>
          <div
            sx={{
              textAlign: "end",
              "@media (max-width: 600px)": {
                textAlign: "left",
                mt: 1,
              },
            }}
          >
            {getFormattedDate(item.start_datetime, "shortDate")} -{" "}
            {getFormattedDate(item.end_datetime, "shortDate")}
          </div>
        </Box>
        <Box>
          <div sx={{ textAlign: "end" }}>
            <EventsMenuItem />
          </div>
        </Box>
      </Grid>
      <Grid
        columns={[2, "9fr 1fr"]}
        sx={{
          "@media (max-width: 600px)": {
            display: "block",
          },
        }}
      >
        <Box>
          <div sx={{ my: 1 }}>
            <b>Organizers:</b> {item.organizers.map((o) => o.name).join(", ")}
          </div>
          <div sx={{ my: 2 }}>
            <b>Event Venue:</b> {item.venue}
          </div>
        </Box>
      </Grid>

      <Dialog
        isOpen={showAddSessionDialog}
        // type={DialogType.close}
        onDismiss={() => setShowAddSessionDialog(false)}
        closeButtonAriaLabel="Close"
        styles={appsDialogStyles.large}
        isBlocking
        dialogContentProps={{
          type: DialogType.normal,
          title: "Add Session",
        }}
      >
        <AddSessions
          item={item}
          onSave={onSave}
          onDiscard={() => setShowAddSessionDialog(false)}
        />
      </Dialog>

      <Dialog
        isOpen={showAddTestimonialDialog}
        // type={DialogType.close}
        onDismiss={() => setShowAddTestimonialDialog(false)}
        closeButtonAriaLabel="Close"
        styles={appsDialogStyles.large}
        isBlocking
        dialogContentProps={{
          type: DialogType.normal,
          title: "Add Testimonial",
        }}
      >
        <AddTestimonial
          item={item}
          onSave={onSave}
          onDiscard={() => setShowAddTestimonialDialog(false)}
        />
      </Dialog>

      <Dialog
        isOpen={showAddPhotosDialog}
        type={DialogType.close}
        onDismiss={() => setShowAddPhotosDialog(false)}
        closeButtonAriaLabel="Close"
        styles={appsDialogStyles.mediumX}
        isBlocking
        dialogContentProps={{
          type: DialogType.normal,
          title: "Add Photos / Event Reports",
        }}
      >
        <AddPhotosAndReports
          item={item}
          onDiscard={() => setShowAddPhotosDialog(false)}
        />
      </Dialog>

      <Dialog
        isOpen={showAttendanceDialog}
        type={DialogType.close}
        onDismiss={dismissDialog}
        title="Bulk Upload Attendance"
        subText=""
        isBlocking
        closeButtonAriaLabel="Close"
        styles={appsDialogStyles.large}
      >
        <Flex
          sx={{
            flexWrap: "wrap",
            gap: 2,
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              p: 3,
              flexGrow: 99999,
              flexBasis: 0,
              minWidth: 200,
              // border: "1px solid #e2e2e2",
              boxShadow: "0px 3px 6px #00000029",
            }}
          >
            <Text sx={{ fontWeight: "medium", fontSize: 3 }}>
              Update Attendance Count:
            </Text>
            <br />
            <Grid my={2} gap={3} columns={[2, "3fr 2fr"]}>
              <Box>Total Attendance</Box>
              <Box>{attendanceCount.captured + attendanceCount.counted}</Box>
              <Box>Captured Attendance</Box>
              <Box>{attendanceCount.captured}</Box>
              <Box>Uncaptured Attendance</Box>
              <Box>{attendanceCount.counted - attendanceCount.captured}</Box>
              <Box mb={2}>
                <Input
                  sx={inputStyles.inputStyle}
                  type="number"
                  name="uncapturedCount"
                  id="uncapturedCount"
                  placeholder="Enter Bulk Count"
                  // value={attendanceCount.counted}
                  value={countValue}
                  onChange={(e) => {
                    const currCount = e.target.value || 0;
                    // setAttendanceCount((prevState) => ({
                    //   ...prevState,
                    //   counted: parseInt(currCount, 10),
                    // }));
                    setCount(parseInt(currCount, 10));
                  }}
                />
                <small>
                  Enter the total count of attendance across all sessions of the
                  event
                </small>
              </Box>
            </Grid>
            <Flex
              sx={{ flexDirection: "row", justifyContent: "flex-end", gap: 2 }}
            >
              {updating && <Spinner size={35} />}
              <DefaultButton
                disabled={updating}
                styles={buttonStyles.blueLight}
                iconProps={{ iconName: "Save" }}
                onClick={updateAttendanceCount}
              >
                {" "}
                Save
              </DefaultButton>
            </Flex>
          </Box>
          <Box
            sx={{
              p: 3,
              flexGrow: 99999,
              flexBasis: 0,
              minWidth: 200,
              // border: "1px solid #e2e2e2",
              boxShadow: "0px 3px 6px #00000029",
            }}
          >
            <AttendanceUpload
              eventObj={item}
              closeModal={() => setShowAttendanceDialog(false)}
            />
          </Box>
        </Flex>
      </Dialog>
    </Container>
  );
}

ScheduleCardC.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  EditItemButton: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default function ScheduleCard({ item, EditItemButton, onSave, key }) {
  return (
    <ScheduleCardC
      item={item}
      EditItemButton={EditItemButton}
      onSave={onSave}
      key={key}
    />
  );
}

ScheduleCard.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  EditItemButton: PropTypes.objectOf(PropTypes.object).isRequired,
  key: PropTypes.objectOf(PropTypes.any).isRequired,
  onSave: PropTypes.objectOf(PropTypes.object).isRequired,
};
