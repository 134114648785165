import jsonTransformer from "sites-common/utils/jsonTransformer";
import moment from "moment";
import { validatePhoneNumber } from "sites-common/utils/validations";
import { get, set } from "lodash";
import applyProdQaConfig from "sites-common/utils/applyProdQaConfig";
import prodCategoryConfig from "../../../data/prodCategoryConfig.json";
import qaCategoryConfig from "../../../data/qaCategoryConfig.json";

export const eventTrackerFormFields = [
  { name: "id", label: "ID", hide: true },
  {
    name: "title",
    label: "Event Title",
    dynamic: (r) => {
      const value = r.title?.replace(/  +/g, " ").trim();
      set(r, "title", value);
    },
  },
  {
    name: "event_json.about",
    label: "Event Description",
    type: "textarea",
    props: { multiline: true },
  },
  {
    name: "start_datetime",
    label: "Start Date",
    type: "datetimetz",
    props: {
      maxDate: moment(new Date()).add(0, "d").format("YYYY-MM-DDTHH:mm:ssZ"),
    },
  },
  {
    name: "end_datetime",
    label: "End Date",
    type: "datetimetz",
    dynamic: (r) =>
      r.start_datetime && {
        props: {
          ...r.props,
          minDate: moment(r.start_datetime).format("YYYY-MM-DDTHH:mm:ssZ"),
          required: true,
          maxDate: moment(new Date())
            .add(0, "d")
            .format("YYYY-MM-DDTHH:mm:ssZ"),
        },
      },
  },
  {
    name: "venue",
    label: "Event Venue",
    props: {
      gridWidth: 300,
      description: "Please mention specific office location, if applicable",
    },
  },
  {
    name: "venue_city.id",
    label: "City",
    type: "srcm_city_id",
    props: {
      gridWidth: 300,
      description: "To be displayed in event page",
    },
  },
  {
    name: "category.id",
    label: "Event Category",
    type: "select",
    options: applyProdQaConfig({
      prod: prodCategoryConfig.results
        .filter((r) => r.active)
        .map((r) => ({
          label: r.complete_name,
          name: r.id,
        })),
      qa: qaCategoryConfig.results
        .filter((r) => r.active)
        .map((r) => ({
          label: r.complete_name,
          name: r.id,
        })),
    }),
    props: {
      styles: {
        dropdownItem: {
          height: "max-content",
          marginBottom: "9px",
        },
        dropdownOptionText: {
          whiteSpace: "break-spaces",
        },
      },
      description: "Select your event category (eg: heartfulness)",
    },
  },
  {
    name: "event_json.event_type",
    label: "Event Type",
    type: "select",
    options: [
      { name: null, label: "" },
      { name: "1-hour", label: "1-hour" },
      { name: "day-1", label: "day-1" },
      { name: "day-2", label: "day-2" },
      { name: "day-3", label: "day-3" },
      { name: "one-day-event", label: "one-day-event" },
      { name: "follow-up", label: "Follow up" },
    ],
    props: {
      gridWidth: 300,
      required: false,
    },
  },

  {
    name: "capacity",
    label: "Capacity",
    type: "number",
    props: {
      gridInline: 110,
      description: "Total participants invited",
    },
  },
  {
    name: "event_json.participants_covered",
    label: "Participants in Scope",
    type: "text",
    props: {
      gridWidth: 300,
      required: false,
      description:
        "Mention if coverage is local / regional or global participants",
    },
  },
  {
    name: "event_json.institution",
    label: "Event conducted for 'Mention the name of the company / institute'",
    type: "text",
    props: {
      gridInline: 300,
      required: false,
      description:
        "Enter name of Organization (Connect events), Sub-center (Group meditation) or Village name (V-Connect).",
    },
  },
  {
    name: "event_json.contact_name",
    label: "Event Co-ordinator from Heartfulness - Name",
    type: "text",
    props: {
      gridInline: 175,
      description:
        "e.g. HFN Family Connect Events, Heartfulness Hyderabad Events, etc.",
    },
  },
  {
    name: "event_json.contact_phone",
    label: "Event Co-ordinator from Heartfulness - Phone",
    type: "number",
    props: {
      gridInline: 175,
      description: "e.g.Enter the phone number of the Event Co-ordinator.",
    },
  },
  {
    name: "event_json.contact_email",
    label: "Event Co-ordinator from Heartfulness - Email",
    type: "email",
    props: {
      gridInline: 175,
      description: "Publicly displayed on event info page",
    },
  },
  {
    name: "srcm_group.id",
    label: "Organizer's SRCM Center",
    type: "srcm_center_id",
    props: {
      gridWidth: 300,
    },
  },
  // {
  //   name: "is_paid_event",
  //   label: "Is Paid Event?",
  //   type: "toggle",
  //   props: {
  //     disabled: true,
  //   },
  // },
  {
    type: "statichtml",
    label: "<br/> Details of the Point of contact from the company",
    name: "cc2",
  },
  {
    name: "event_json.coordinator_name",
    label: "Point of contact Name",
    type: "text",
    props: {
      gridInline: 175,
      required: false,
      description: "Enter the name of the coordinator for the event.",
    },
  },
  {
    name: "event_json.coordinator_phone",
    label: "Point of contact Phone",
    type: "number",
    props: {
      gridInline: 175,
      required: false,
      description: "Enter the phone number of the coordinator.",
    },
  },
  {
    name: "event_json.coordinator_email",
    label: "Point of contact Email",
    type: "email",
    props: {
      gridInline: 175,
      required: false,
      description: "Enter the email address of the coordinator.",
    },
  },
  {
    name: "event_json.trainer_id",
    label: "Trainer ID",
    type: "text",
    props: {
      gridInline: 175,
      required: false,
      description: "Enter the trainer id",
    },
  },
  {
    name: "event_json.trainer_email",
    label: "Heartfulness Trainer for the event - Email",
    type: "email",
    props: {
      gridInline: 175,
      required: false,
      description:
        "Enter the email of the trainer (preceptor) for the event if possible.",
    },
  },
  {
    name: "counted_attendance",
    label: "Counted Attendance",
    type: "number",
    props: {
      gridInline: 110,
      required: false,
      description: " Enter the counted attendance",
    },
  },

  // {
  //   name: "event_json.donate",
  //   label: "Suggested Donation",
  //   type: "number",
  //   props: {
  //     required: false,
  //     gridInline: 200,
  //     description: "Per participant",
  //   },
  // },
];

export const postprocess = (r) => {
  const ret = jsonTransformer(
    [
      { t: "rel2id", i: ["srcm_group"], o: ["srcm_group"] },
      { t: "rel2id", i: ["venue_city"], o: ["venue_city"] },
      { t: "rel2id", i: ["category"], o: ["category"] },
      { t: "rel2id", i: ["venue_country"], o: ["venue_country"] },
      { t: "relArr2idArr", i: ["organizers"], o: ["organizers"] },
      { t: "omit", i: ["created_by_partner"] },
      { t: "omit", i: ["write_date"] },
      { t: "omit", i: ["create_date"] },
      { t: "omit", i: ["one_status"] },
      { t: "omit", i: ["id"] },
      { t: "omit", i: ["zone"] },
      { t: "copy", i: ["is_paid_event"], o: ["is_paid_event"] },
    ],
    r
  );
  return ret;
};

export const eventTrackerFormDefaults = {
  online_registration: false,
  register_link: null,
  is_venue_online: true,
  capacity: 0,
  is_paid_event: false,
  event_json: {},
  enable_registration: false,
  display_on_calender: false,
  reporting_only: true,
  start_datetime: moment().startOf("date").format("YYYY-MM-DDTHH:mm:ssZ"),
  end_datetime: moment()
    .startOf("date")
    .add(-1, "d")
    .format("YYYY-MM-DDTHH:mm:ssZ"),
};

export function eventsTracketFormValidation(r) {
  const capacity = get(r, ["capacity"]);
  const phone = get(r, ["event_json", "coordinator_phone"]);
  const name = get(r, ["event_json", "coordinator_name"]);
  const title = get(r, ["title"]);
  const eventDescription = get(r, ["event_json", "about"]);
  const start_datetime = moment(get(r, ["start_datetime"]));
  const end_datetime = moment(get(r, ["end_datetime"]));
  const contact_phone = get(r, ["event_json", "contact_phone"]);

  if (start_datetime > end_datetime) {
    return "End date&time should be greater than start date&time";
  }
  if (capacity < 10) {
    return "Capacity should be greater than 10";
  }
  const specialCharacRegex = new RegExp(/^([a-zA-Z0-9]+\s?)*$/);
  if (name && !specialCharacRegex.test(name.trim())) {
    return "Please enter valid contact name";
  }
  if (!specialCharacRegex.test(title.trim())) {
    return "Event title cannot contain special characters.";
  }
  const descriptionCharacRegex = new RegExp(
    /^([a-zA-Z0-9~!@#$%^&()\\,./"><|]+\s?)*$/
  );
  if (!descriptionCharacRegex.test(eventDescription)) {
    return "Special Characters are not allowed for Event Description field.";
  }
  if (phone && !validatePhoneNumber(`+${phone}`)) {
    return "Please enter a valid phone number alone with country code, example 918888888888.";
  }
  if (contact_phone && !validatePhoneNumber(`+${contact_phone}`)) {
    return "Please enter a valid phone number alone with country code, example 918888888888.";
  }
  return false;
}
