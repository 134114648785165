/** @jsx jsx */
import { Box, Flex, jsx } from "theme-ui";
import { useSrcmApiStore } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { IndexWithRouter } from "@heartfulnessinstitute/react-hfn-forms/dist/ui/CrudTemplate";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import { selectorFirebaseAuthProfile } from "gatsby-plugin-hfn-profile/state/selectors";
import { isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
// import { DefaultButton } from "office-ui-fabric-react";
// import { navigate } from "@reach/router";
import { useMemo } from "react";
import PropTypes from "prop-types";
// import { buttonStyles } from "sites-common/utils/fabricStyles";
import { get } from "lodash";
import Config from "./Config";

// const goBack = () => navigate(-1);

function useConfig(uri) {
  const profile = useAuth();

  const loggedUser = useMemo(
    () => ({
      id: get(profile, "srcmProfile.id"),
      name: get(profile, "srcmProfile.name"),
    }),
    [profile]
  );

  const formDefaults = useMemo(
    () => ({
      ...Config.formDefaults,
      organizers: [loggedUser],
      ...(get(profile, "srcmProfile.city")
        ? {
            venue_city: {
              id: get(profile, "srcmProfile.city_id"),
              name: get(profile, "srcmProfile.city"),
            },
          }
        : {}),
      ...(get(profile, "srcmProfile.srcm_group")
        ? {
            srcm_group: {
              id: get(profile, "srcmProfile.srcm_group_id"),
              name: get(profile, "srcmProfile.srcm_group"),
            },
          }
        : {}),
    }),
    [profile, loggedUser]
  );

  const srcmstoreDb = useSrcmApiStore(Config.collection, {
    api: "/api/v3/events/",
    primaryKey: Config.primaryKey,
    client: "eventsClient",
    methodParams: {
      reporting_only: true,
    },
  });

  const myConfig = {
    ...Config,
    formDefaults,
    db: srcmstoreDb,
    homeUrl: () => uri,
  };
  return myConfig;
}

function Main(props) {
  const { uri, location } = props;
  const myConfig = useConfig(uri);
  const auth = useSelector(selectorFirebaseAuthProfile);
  const authLoaded = isLoaded(auth);

  return (
    <Box sx={{ p: 3 }}>
      <Flex sx={{ my: 2 }}>
        <Box sx={{ fontSize: 3, fontWeight: "medium", flex: "1 1 auto" }}>
          {location.pathname === `${uri}/new` &&
            "Heartfulness Event Summary Reporting"}
        </Box>
        <Box>
          {/* <DefaultButton
            styles={buttonStyles.default}
            iconProps={{ iconName: "Back" }}
            text="Back"
            onClick={goBack}
          /> */}
        </Box>
      </Flex>

      {authLoaded && <IndexWithRouter Config={myConfig} />}
    </Box>
  );
}

Main.defaultProps = {
  uri: "",
  location: {},
};

Main.propTypes = {
  uri: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any),
};

export default Main;
